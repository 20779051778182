import React, { useEffect } from "react";
import { Icon, ReactDataTable } from "../../../components/Component";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import { getPastEventsList } from "../../../http/getApi";
import { ShimmerTable } from "react-shimmer-effects";
import { formatDate } from "../../../utils/Utils";

export const NotAvailable = () => (
  <div style={{ textAlign: "center" }}>----</div>
);

const PastEvents = () => {
  const navigate = useNavigate();

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");

  //fetching event data
  const {
    data: pastEventsList,
    isLoading: pastEventsLoading,
    isError: pastEventsError,
  } = useQuery({
    queryKey: ["get-past-events-list", pageNo, recordLimit, search],
    queryFn: () =>
      getPastEventsList({
        id: "",
        page: pageNo,
        limit: recordLimit,
        search: search,
      }),
  });

  const tableHeaddings = [
    {
      name: "SR no.",
      selector: (row, index) => index + 1,
      maxWidth: "5px",
    },
    {
      name: "Event Name",
      selector: (row) => row.event_name || <NotAvailable />,
    },
    {
      name: "Event Type",
      selector: (row) => row.event_type_name || <NotAvailable />,
    },
    {
      name: "Event Date",
      selector: (row) => formatDate(row.start_date_time) || <NotAvailable />,
    },

    {
      name: "Package Name",
      selector: (row) =>
        row.package_details[0]?.package_name || <NotAvailable />,
    },
    {
      name: "Venue",
      selector: (row) => row.venue_details[0]?.city || <NotAvailable />,
    },
  ];

  return (
    <div>
      {pastEventsList && pastEventsList?.data?.length > 0 && (
        <div className="p-4 border mb-4 bg-white">
          <h4 className="mb-3">Previous Events</h4>
          {pastEventsLoading ? (
            <ShimmerTable row={10} />
          ) : (
            <ReactDataTable
              data={pastEventsList?.data || []}
              columns={tableHeaddings}
              expandableRows
              pagination
              actions
              searchBy="event_name"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PastEvents;
