import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useNavigate, useParams } from "react-router";
import classNames from "classnames";
import PaymentDetails from "./PaymentDetails";
import { Icon } from "../../../../components/Component";
import { useQuery } from "react-query";
import {
  getPaymentDetails,
  getUpcomingEventsList,
} from "../../../../http/getApi";
import Agreement from "./Agreement";
import { formatDate, formatTime } from "../../../../utils/Utils";
import Information from "./Information";
import TransactionDetails from "./TxnDetails";
import Review from "./Review";

const EventDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeTab, setActivetab] = useState("1");
  const toggleIconTab = (tab) => {
    if (activeTab !== tab) {
      setActivetab(tab);
    }
  };

  //fetching event data
  const {
    data: upcomingEvent,
    isLoading: upcomingEventLoading,
    isError: upcomingEventListError,
  } = useQuery({
    queryKey: ["get-upcoming-event-by-id", id],
    queryFn: () =>
      getUpcomingEventsList({ id: id, page: "", limit: "", search: "" }),
  });

  //fetching event payment details
  const {
    data: paymentDetails,
    isLoading: paymentDetailsLoading,
    isError: paymentDetailsError,
  } = useQuery({
    queryKey: ["get-paymentDetails-by-clientId", id],
    queryFn: () =>
      getPaymentDetails({ id: id, page: "", limit: "", search: "" }),
  });

  const clientName = upcomingEvent?.data[0]?.client_details?.[0]?.first_name;
  const email = upcomingEvent?.data[0]?.client_details?.[0]?.email;
  const eventName = upcomingEvent?.data[0]?.event_name;
  const packageName =
    upcomingEvent?.data[0]?.package_details?.[0]?.package_name;
  const eventType = upcomingEvent?.data[0]?.event_type_name;
  const address = upcomingEvent?.data[0]?.venue_details?.[0]?.address;
  const zipCode = upcomingEvent?.data[0]?.venue_details?.[0]?.zip_code;
  const city = upcomingEvent?.data[0]?.venue_details[0]?.city;
  const eventdate = formatDate(upcomingEvent?.data[0]?.start_date_time);
  const contactNumber =
    upcomingEvent?.data[0]?.client_details?.[0]?.work_phone_number;
  const packagePrice =
    upcomingEvent?.data?.[0]?.package_details?.[0]?.package_price;
  const retainerPrice =
    upcomingEvent?.data?.[0]?.event_financials?.retainer_value;
  const addOnTotal = upcomingEvent?.data?.[0]?.event_financials?.addon_total;
  const travelFee = upcomingEvent?.data[0]?.venue_details[0]?.travel_cost || 0;
  const discount1 = upcomingEvent?.data[0]?.event_financials?.discount1 || 0;
  const discount2 = upcomingEvent?.data[0]?.event_financials?.discount2 || 0;
  const balanceDueAfterRetainer =
    upcomingEvent?.data[0]?.event_financials?.balance_due_after_retainer || 0;
  const totalFee =
    balanceDueAfterRetainer +
    upcomingEvent?.data?.[0]?.event_financials?.retainer_value;
  const scheduledPayment2 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2;
  const scheduledPayment3 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3;
  const scheduledPayment2Date = formatDate(
    upcomingEvent?.data?.[0]?.scheduled_payment_2
  );
  const scheduledPayment3Date = formatDate(
    upcomingEvent?.data?.[0]?.scheduled_payment_3
  );
  const eventFinancials = upcomingEvent?.data?.[0]?.event_financials;
  const guestCount = upcomingEvent?.data[0]?.guest_count;
  const setUpTime = formatTime(upcomingEvent?.data[0]?.setup_time);
  const startTime = formatTime(upcomingEvent?.data[0]?.start_date_time);
  const endTime = formatTime(upcomingEvent?.data[0]?.end_date_time);
  const userId = upcomingEvent?.data?.[0]?.user_id;
  const retainerTxnId =
    upcomingEvent?.data?.[0]?.event_financials?.retainer_txn;
  const schedulePay2TxnId =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2_txn;
  const schedulePay3TxnId =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3_txn;
  const retainerPaidDate = formatDate(
    paymentDetails?.data?.done_payment?.retainer_payment?.created_at
  );

  const scheduled2PaidDate = formatDate(
    paymentDetails?.data?.done_payment?.scheduled_payment_2?.created_at
  );

  const scheduled3PaidDate = formatDate(
    paymentDetails?.data?.done_payment?.scheduled_payment_3?.created_at
  );

  const status = upcomingEvent?.data?.[0]?.event_financials?.status;

  return (
    <>
      <div className="mt-3">
        <h4>Event Details</h4>
        <div class="card card-preview mt-3">
          <div className="card-inner pt-0">
            <Nav tabs>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <em class="icon ni ni-calender-date-fill"></em>
                  <span>Financials</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "2" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <em class="icon ni ni-info-i"></em>
                  <span>Information </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "3" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("3");
                  }}
                >
                  <Icon name="sign-usd"></Icon>
                  <span>Transaction Details</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "4" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("4");
                  }}
                >
                  <Icon name="file-plus"></Icon>
                  <span>Agreement</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "5" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("5");
                  }}
                >
                  <em class="icon ni ni-star-fill"></em>
                  <span>Review</span>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <PaymentDetails
                  upcomingEvent={upcomingEvent}
                  packagePrice={packagePrice}
                  retainerPrice={retainerPrice}
                  addOnTotal={addOnTotal}
                  travelFee={travelFee}
                  discount1={discount1}
                  discount2={discount2}
                  balanceDueAfterRetainer={balanceDueAfterRetainer}
                  totalFee={totalFee}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  scheduledPayment2Date={scheduledPayment2Date}
                  scheduledPayment3Date={scheduledPayment3Date}
                  eventFinancials={eventFinancials}
                  userId={userId}
                  paymentDetails={paymentDetails}
                  paymentDetailsLoading={paymentDetailsLoading}
                  clientName={clientName}
                  eventdate={eventdate}
                />
              </TabPane>
              <TabPane tabId="2">
                <Information
                  upcomingEvent={upcomingEvent}
                  guestCount={guestCount}
                  eventName={eventName}
                  eventType={eventType}
                  eventdate={eventdate}
                  setUpTime={setUpTime}
                  startTime={startTime}
                  endTime={endTime}
                  packageName={packageName}
                  packagePrice={packagePrice}
                  address={address}
                  zipCode={zipCode}
                  city={city}
                />
              </TabPane>
              <TabPane tabId="3">
                <TransactionDetails
                  packageName={packageName}
                  packagePrice={packagePrice}
                  retainerPrice={retainerPrice}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  retainerTxnId={retainerTxnId}
                  schedulePay2TxnId={schedulePay2TxnId}
                  schedulePay3TxnId={schedulePay3TxnId}
                  paymentDetails={paymentDetails}
                  clientName={clientName}
                  eventdate={eventdate}
                  retainerPaidDate={retainerPaidDate}
                  scheduled2PaidDate={scheduled2PaidDate}
                  scheduled3PaidDate={scheduled3PaidDate}
                />
              </TabPane>
              <TabPane tabId="4">
                <Agreement
                  clientName={clientName}
                  email={email}
                  eventName={eventName}
                  packageName={packageName}
                  eventType={eventType}
                  address={address}
                  zipCode={zipCode}
                  eventdate={eventdate}
                  contactNumber={contactNumber}
                  retainerPrice={retainerPrice}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  scheduledPayment2Date={scheduledPayment2Date}
                  scheduledPayment3Date={scheduledPayment3Date}
                  schedulePay2TxnId={schedulePay2TxnId}
                  schedulePay3TxnId={schedulePay3TxnId}
                  retainerTxnId={retainerTxnId}
                  retainerPaidDate={retainerPaidDate}
                  scheduled2PaidDate={scheduled2PaidDate}
                  scheduled3PaidDate={scheduled3PaidDate}
                  status={status}
                />
              </TabPane>
              <TabPane tabId="5">
                <Review />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
