import React from "react";
import { Container, Row, Col } from "reactstrap";
import Cookies from "js-cookie";
import { getCookie } from "../../../utils/Utils";

const WelcomeSection = () => {
  // const userName = Cookies.get("username");
  const userName = getCookie("username");

  const slogans = [
    {
      sentence1: "Hey there,",
      sentence2: "Ready to groove to the rhythm of good times?",
    },
    {
      sentence1: "Welcome,",
      sentence2: "Let's pump up the volume and get this party started.",
    },
    {
      sentence1: "Greetings,",
      sentence2: "Let's make tonight epic with beats that move your feet.",
    },
    {
      sentence1: "Welcome,",
      sentence2: "It's time to unleash your inner party animal.",
    },
    {
      sentence1: "Greetings,",
      sentence2: "Get set to groove to the tunes of the night.",
    },
    {
      sentence1: "Welcome,",
      sentence2: "Tonight's agenda: dance, laugh, and make memories.",
    },
    {
      sentence1: "Hey there,",
      sentence2: "Get ready to lose yourself in the music and find pure joy.",
    },
  ];

  const randomSlogan = slogans[Math.floor(Math.random() * slogans.length)];

  return (
    <Container
      style={{ borderBottom: "1px solid gray", paddingBottom: "30px" }}
    >
      <Row className="px-3">
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <h3 className="ff-base fw-medium text-center">
            <small className="text-soft mx-1">{randomSlogan.sentence1}</small>
            {userName}
            <small className="text-soft mx-1">{randomSlogan.sentence2}</small>
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomeSection;
