import { Col, Row } from "reactstrap";
import Countdown from "../../components/countdown/Countdown";
import UpcommingEvents from "../myEvents/components/UpcommingEvents";
import WelcomeSection from "./components/WelcomeSection";
import welcome from "../../assets/images/Welcome.svg";
import { useEffect } from "react";
import Cookies from "js-cookie";

const Home = () => {
  return (
    <>
      <Row className="d-flex align-items-center">
        <Col xs="12" md="6">
          <img src={welcome} alt="Welcome" />
        </Col>
        <Col xs="12" md="6">
          <UpcommingEvents />
        </Col>
        <WelcomeSection />
        {/* <Countdown /> */}
      </Row>
    </>
  );
};
export default Home;
