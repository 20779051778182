import { getCookie } from "../utils/Utils";

let token = process.env.REACT_APP_AUTH_TOKEN;

// Get API to fetch past events List =================================
export const getPastEventsList = async (values) => {
  const formData = new FormData();

  if (values.page !== "") {
    formData.append("page", values.page);
  }
  if (values.limit !== "") {
    formData.append("limit", values.limit);
  }
  if (values.search !== "") {
    formData.append("search_keyword", values.search);
  }

  formData.append("role", "client");
  formData.append("categorize_event", "history");

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/events/get-event`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Get API to fetch upcoming events List =================================
export const getUpcomingEventsList = async (values) => {
  const formData = new FormData();

  if (values.id !== "") {
    formData.append("id", values.id);
  }

  formData.append("role", "client");
  formData.append("categorize_event", "upcoming");

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/events/get-event`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting events list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Get API to fetch client payment details =================================
export const getPaymentDetails = async (values) => {
  const formData = new FormData();

  formData.append("event_id", values.id);

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/client/event-payment-details `,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while payment details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Get API to fetch Client details =================================
export const getClientsList = async () => {
  const formData = new FormData();
  formData.append("type", "client");

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/client/get-client`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting client details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

// Get API to fetch Notifications =================================
export const getNotifications = async (values) => {
  const formData = new FormData();

  if (values.limit) {
    formData.append("limit", values.limit);
  }
  if (values.offset) {
    formData.append("offset", values.offset);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/get-notification`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error(
      "An Error occured while getting notifications list"
    );
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};
